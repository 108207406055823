import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import { Link, graphql, StaticQuery } from "gatsby";

class ServiceList extends React.Component {
  render() {
    const { data } = this.props;
    const nestedIn = this.props.nestedIn;
    const totalCount = data.allMarkdownRemark.totalCount;
    const relatedServices = this.props.relatedServices;

    let projectList = {
      ...data.allMarkdownRemark,
    };

    let dataList = [];
    const node = projectList.edges;

    if (nestedIn === 'case-study-post') {
      for (let j = 0; j <= relatedServices.length - 1; j++) {
        for (let k = 0; k <= totalCount - 1; k++) {
          const rec = node[k];
          if (rec.node.frontmatter.title === relatedServices[j].title) {
            dataList.push({
              node: rec.node,
            })
          }
        }
      }
    } else {
      for (let i = 0; i <= totalCount - 1; i++) {
        const rec = node[i];
        dataList.push({
          node: rec.node,
        });
      }
    }

    projectList = {
      edges: dataList,
    };

    const { edges: posts } = projectList;

    if (nestedIn === 'header') {
      return (
        <ul className="font-medium md:text-base text-sm">
          {posts &&
            posts.map(({ node: post }) => (
              <li className="border-b border-gray-300 mb-3 md:border-none md:hover:underline md:mb-6 pb-3" key={post.id}>
                <Link className="block" onClick={() => this.toggleHamburger()} to={post.fields.slug}>
                  {post.frontmatter.title}
                </Link>
              </li>
            ))
          }
        </ul>
      )
    }

    if (nestedIn === 'footer') {
      return (
        <>
          {posts &&
            posts.map(({ node: post }) => (
              <Link className="hover:underline focus:outline-none focus:underline lg:mt-4 mt-2" to={post.fields.slug} key={post.id}>
                {post.frontmatter.title}
              </Link>
            ))
          }
        </>
      )
    }

    if (nestedIn === 'index-page') {
      return (
        <ul className="md:col-gap-24 md:grid md:grid-cols-3 px-6">
          {posts &&
            posts.map(({ node: post }) => (
              <li className="max-w-sm mb-8 md:mb-24" key={post.id}>
              <Img fixed={post.frontmatter.icon.childImageSharp.fixed} objectFit="contain" />
              <h3 className="font-medium md:text-xl my-3 text-lg text-white">{post.frontmatter.title}</h3>
              <p className="mb-8 md:leading-relaxed md:text-lg text-indigo-300">{post.frontmatter.description}</p>
              <Link className="border-b border-blue-300 focus:outline-none focus:text-blue-300 font-medium hover:text-blue-300 inline-flex items-center mb-12 md:mb-0 md:text-lg pb-2 text-white text-link" to={post.fields.slug}>
                Learn more
                <span className="sr-only">about {post.frontmatter.title}</span>
                <svg className="ml-4 md:ml-6 mt-1" fill="#90CDF4" height=".8em" width=".8em">
                  <use xlinkHref="#icon-rightarrow"></use>
                </svg>
              </Link>
            </li>
            ))
          }
        </ul>
      )
    }

    if (nestedIn === 'case-study-post') {
      return (
        <>
          {posts &&
            posts.map(({ node: post }) => (
              <div className="max-w-sm md:max-w-md relative">
                <Link className="bg-white flex focus:outline-none items-center mb-5 md:pr-6 md:py-10 py-8 service-link"
                  to={post.fields.slug} key={post.id}>
                  <div className="md:mr-10 md:pl-10 md:w-4/5 mr-8 pl-6 w-3/4">{post.frontmatter.title}</div>
                  <div className="mr-6 md:mr-3">
                    <svg
                      className="service-linkIcon border border-gray-400 flex h-8 items-center justify-center md:h-10 md:p-3 md:w-10 p-2 right-0 rounded-full top-0 w-8"
                      fill="#161C54"
                      height=".7em"
                      width=".7em"
                    >
                      <use xlinkHref="#icon-rightarrow"></use>
                    </svg>
                  </div>
                </Link>
              </div>
            ))
          }
        </>
      )
    }
  }
}

ServiceList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query ServiceListQuery {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___featuredorder] }
          filter: { frontmatter: { templateKey: { eq: "solution-detail" } } }
        ) {
          totalCount
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                templateKey
                title
                icon {
                  childImageSharp {
                    fixed(height: 95, quality: 80) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                description
              }
            }
          }
        }
      }
    `}
    render={(data) => <ServiceList data={data} {...props} />}
  />
);