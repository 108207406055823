import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

class ProductList extends React.Component {
  render() {
    const { frontmatter } = this.props.data.markdownRemark;
    const nestedIn = this.props.nestedIn;

    let dataList = [];

    if (nestedIn === 'solution-detail') {
      const relatedProduct = this.props.relatedProduct;
      for (let j = 0; j <= relatedProduct.length - 1; j++) {
        for (let k = 0; k <= frontmatter.products.length - 1; k++) {
          const rec = frontmatter.products[k];
          if (rec.title === relatedProduct[j].title) {
            dataList.push({
              ...rec,
            })
          }
        }
      }
    } else {
      dataList = frontmatter.products
    }

    if (nestedIn === 'header') {
      return (
        <ul>
          {dataList &&
            dataList.map((product) => (
              <li className="border-b border-gray-300 mb-4 md:border-none pb-4 text-2xl" key={product.title}>
                <a className="hover:opacity-75"  
                  href={product.link}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className="inline-flex items-center md:mb-2">
                    {product.title}
                      <svg 
                        className="ml-2"  
                        fill="#151B53"
                        height=".6em" 
                        width=".6em">
                        <use xlinkHref="#icon-rightarrow"></use>
                      </svg>
                  </div> 
                  <div className="md:w-4/5 text-gray-600 text-sm w-3/4">{product.description}</div>
                </a>
              </li>
            ))
          }
        </ul>
      )
    }

    if (nestedIn === 'footer') {
      return (
        <>
          {dataList &&
            dataList.map((product, i) => (
              <Link className="hover:underline focus:outline-none focus:underline lg:mt-4 mt-2" to={product.link} key={i}>
                {product.title}
              </Link>
            ))
          }
        </>
      )
    }

    if (nestedIn === 'index-page') {
      return (
        <ul className="max-w-6xl mx-auto products-list">
          {dataList &&
            dataList.map((product, i) => (
                <li className="justify-between mb-12 md:flex md:flex-wrap md:items-center md:mb-0 mx-auto">
                  <div className="md:w-1/2 my-12 products-image w-full">
                    <PreviewCompatibleImage imageInfo={product.image} />
                  </div>
                  <div className="max-w-sm md:w-1/2 px-8">
                    <div className="mb-6 w-64">
                      <PreviewCompatibleImage imageInfo={product.logo} />
                    </div>
                    <p className="leading-relaxed mb-12 md:mb-16 md:text-lg text-gray-600">
                      {product.text}
                    </p>
                    <a className="border-2 border-blue-300 focus:bg-blue-300 focus:outline-none font-medium hover:bg-blue-300 py-4 px-6 rounded-full text-indigo-900" href={product.link} rel="noopener noreferrer" target="_blank">
                      Learn more <span className="sr-only"> about {product.title}</span>
                    </a>
                  </div>
                </li>
            ))
          }
        </ul>
      )
    }

    if (nestedIn === 'solution-detail') {
      return (
        <ul>
          {dataList &&
            dataList.map((product, i) => (
                <li className="justify-between md:flex md:flex-wrap md:items-center px-4 mx-auto" key={product.title}>
                  <div className="md:pr-8 md:w-1/2 my-12 w-full">
                    <PreviewCompatibleImage imageInfo={product.image} />
                  </div>
                  <div className="max-w-lg md:w-1/2 px-4 w-10/12">
                    <div className="mb-6 w-64">
                      <PreviewCompatibleImage imageInfo={product.logo} />
                    </div>
                    <p className="mb-12 md:mb-16 md:mt-6 md:text-4xl text-xl text-gray-600">
                      {product.description}
                    </p>
                    <a className="border-2 border-blue-300 focus:bg-blue-300 focus:outline-none font-medium hover:bg-blue-300 py-4 px-6 rounded-full text-indigo-900" href={product.link} rel="noopener noreferrer" target="_blank">
                      Learn more <span className="sr-only"> about {product.title}</span>
                    </a>
                  </div>
                </li>
            ))
          }
        </ul>
      )
    }
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query ProductListQuery {
        markdownRemark(frontmatter: { key: { eq: "product" } }) {
          frontmatter {
            key
            title
            products {
              title
              logo {
                childImageSharp {
                  fluid(maxWidth: 650, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              description
              link
              image {
                childImageSharp {
                  fluid(maxWidth: 650, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              text
            }
          }
        }
      }
    `}
    render={(data) => <ProductList data={data} {...props} />}
  />
);
