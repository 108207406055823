import React from "react";
import { Link } from "gatsby";
import CaseStudyList from "./CaseStudyList";
import ServiceList from "./ServiceList";
import ProductList from "./ProductList";

const Footer = () => (
  <footer className="bg-indigo-900 md:pt-20 pb-20">
    <div className="max-w-screen-xl mx-auto py-10">
      <div className="lg:flex">
        <div className="lg:justify-between lg:flex-grow lg:mr-16 px-6 my-4">
          <Link to="/"
            aria-label="CVision" className="logo-link focus:outline-none">
            <svg
              className="logo-symbol absolute fill-current mb-10 md:mb-0"
              height="2em"
              width="2em">
              <use xlinkHref="#icon-cvision-symbol"></use>
            </svg>
            <svg
              className="fill-current mb-10 md:mb-0"
              height="2em"
              width="10em">
              <use xlinkHref="#icon-cvision-word"></use>
            </svg>
          </Link>
        </div>
        <div className="lg:grid lg:grid-cols-4 mt-6 px-8">
          <div className="flex flex-col flex-grow lg:mt-0 lg:pl-20 mt-10">
            <h2 className="font-medium text-blue-300">Products</h2>
            <ProductList nestedIn='footer' />
          </div>
          <div className="flex flex-col flex-grow lg:mt-0 lg:pl-20 mt-10">
            <h2 className="font-medium text-blue-300">Services</h2>
            <ServiceList nestedIn='footer' relatedServices={null} />
          </div>
          <div className="flex flex-col flex-grow lg:mt-0 lg:pl-20 mt-10">
            <h2 className="font-medium text-blue-300">Case Studies</h2>
            <CaseStudyList />
          </div>
          <div className="flex flex-col flex-grow lg:mt-0 lg:pl-20 mt-10">
            <h2 className="font-medium text-blue-300">Company</h2>
            <Link className="hover:underline focus:outline-none focus:underline lg:mt-4 mt-2" to="/about">About Us</Link>
            <Link className="hover:underline focus:outline-none focus:underline lg:mt-4 mt-2" to="/careers">Careers</Link>
            <Link className="hover:underline focus:outline-none focus:underline lg:mt-4 mt-2" to="#contact">Contact Us</Link>
          </div>
        </div>
      </div>
      <div className="lg:flex lg:mt-32 mt-16 px-8">
        <div className="flex mb-16 lg:items-baseline lg:m-0 mt-4 order-2">
          <a
            aria-label="CVision's LinkedIn"
            className="mr-5 text-blue-300 hover:text-white focus:outline-none focus:text-white"
            href="https://www.linkedin.com/company/cvision-ai/"
            rel="noopener noreferrer"
            target="_blank"
          >
              <svg className="fill-current" height="1.4em" width="1.4em">
                <use xlinkHref="#icon-linkedin2"></use>
              </svg>
          </a>
          <a
            aria-label="CVision’s GitHub"
            className="mr-5 text-blue-300 hover:text-white focus:outline-none focus:text-white"
            href="https://github.com/cvisionai"
            rel="noopener noreferrer"
            target="_blank"
          >
            <svg className="fill-current" height="1.4em" width="1.4em">
              <use xlinkHref="#icon-github"></use>
            </svg>
          </a>
        </div>
        <div className="flex flex-col lg:flex-grow lg:flex-row lg:inline-flex lg:items-baseline lg:mr-16 lg:text-base order-1 text-white text-xs">
          © 2020, CVision AI. All Rights Reserved.
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;

