import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";

class CaseStudyList extends React.Component {
  render() {
    const { data } = this.props;
    const totalCount = data.allMarkdownRemark.totalCount;

    let projectList = {
      ...data.allMarkdownRemark,
    };

    let dataList = [];
    const node = projectList.edges;

    for (let i = 0; i <= totalCount - 1; i++) {
      const rec = node[i];
      dataList.push({
        node: rec.node,
      });
    }

    projectList = {
      edges: dataList,
    };

    const { edges: posts } = projectList;

    return (
      <>
        {posts &&
          posts.map(({ node: post }) => (
            <Link className="hover:underline focus:outline-none focus:underline lg:mt-4 mt-2" to={post.fields.slug} key={post.id}>
              {post.frontmatter.title}
            </Link>
          ))}
      </>
    );
  }
}

CaseStudyList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query CaseStudyListQuery {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___featuredorder] }
          filter: { frontmatter: { templateKey: { eq: "case-study-post" } } }
        ) {
          totalCount
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                templateKey
                title
              }
            }
          }
        }
      }
    `}
    render={(data) => <CaseStudyList data={data} {...props} />}
  />
);
