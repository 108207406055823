import React from "react";
import { Link } from "gatsby";
import headerStyles from "./Header.module.scss";
import OutsideClickHandler from 'react-outside-click-handler';
import ServiceList from "./ServiceList";
import ProductList from "./ProductList";

const Header = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hamburgerActive: false,
      hamburgerActiveClass: "",
      solutionsActive: false,
      solutionsActiveClass: "",
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        hamburgerActive: !this.state.hamburgerActive,
        solutionsActive: false,
        solutionsActiveClass: "",
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.hamburgerActive
          ? this.setState({
              navBarActiveClass: headerStyles.isActive,
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  };

  openSolutions = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        solutionsActive: true,
        solutionsActiveClass: headerStyles.isActive,
      },
    );
  };

  closeSolutions = () => {
    this.setState(
      {
        solutionsActive: false,
        solutionsActiveClass: "",
      },
    );
  }

  render() {
    return (
      <header className={headerStyles.header + ` absolute md:h-auto w-full z-10 ${this.state.navBarActiveClass}`}>
        <nav aria-label="main-navigation" className={headerStyles.nav + " flex flex-col font-serif h-full justify-between max-w-screen-xl md:flex-row mx-auto md:items-center relative w-full text-xl px-6"} role="navigation">
          <div className={headerStyles.navWrap + " nav-wrap flex items-center justify-between"}>
            <Link to="/"
              aria-label="CVision"
              className="logo-link focus:outline-none md:text-3xl text-current"
              >
              <svg 
                className="logo-symbol fill-current absolute"
                height="1em"
                width="1em">
                <use xlinkHref="#icon-cvision-symbol"></use>
              </svg>
              <svg 
                className="fill-current"
                height="1em"
                width="5em">
                <use xlinkHref="#icon-cvision-word"></use>
              </svg>
            </Link>
            <button
              aria-label="Toggle Menu"
              className="focus:outline-none md:hidden"
              onClick={() => this.toggleHamburger()}
            >
              <svg
                className={headerStyles.iconHamburger + " fill-current"}
                height="1em"
                width="1em">
                <use xlinkHref="#icon-mobilemenu"></use>
              </svg>
              <svg 
                className={headerStyles.iconClose + " hidden fill-current"}
                height="1em" 
                width="1em">
                <use xlinkHref="#icon-close"></use>
              </svg>
            </button>
          </div>
          <div className={headerStyles.mainNav + " hidden md:flex md:font-sans md:inline-flex md:justify-between md:text-base md:w-full text-indigo-800"}>
            <div className="flex flex-col md:flex-row md:items-center">
              <button
                className={headerStyles.solutionsTrigger + ` focus:outline-none nav__link inline-flex items-center md:block md:ml-12 md:text-blue-300 md:relative ${this.state.solutionsActiveClass}`}
                onClick={() => this.openSolutions()}
                >
                <span className="md:text-white md:flex md:items-center">
                  Solutions
                  <svg
                    className="fill-current solutions-expand hidden md:flex text-blue-300"
                    height=".35em"
                    width="1em">
                    <use xlinkHref="#icon-chevron-down"></use>
                  </svg>
                </span>
                <svg 
                  className="md:hidden ml-2"
                  fill="#151B53"
                  height=".7em" 
                  width=".7em">
                  <use xlinkHref="#icon-rightarrow"></use>
                </svg>
              </button>
              <OutsideClickHandler onOutsideClick={() => {this.closeSolutions()}}>
                <ul className={headerStyles.solutionsNav + " font-sans hidden max-w-3xl md:absolute md:bg-white md:left-0 md:mx-6 md:rounded-md md:z-10"}>
                  <li className="flex-shrink-0 mb-4 md:mb-16 md:border-r md:border-gray-300 md:mb-6 md:ml-12 md:mr-8 md:mt-8 md:pt-2">
                    <div className="font-medium mb-3 md:mb-4 md:text-sm text-gray-600 text-xs uppercase">Products</div>
                    <ProductList nestedIn='header' />
                  </li>
                  <li className="md:mr-12 md:mt-10">
                    <div className="font-medium mb-6 md:mb-8 md:text-sm text-gray-600 text-xs uppercase">Services</div>
                    <ServiceList nestedIn='header' relatedServices={null} />
                  </li>
                </ul>
              </OutsideClickHandler>
              <Link
                activeClassName={headerStyles.isActive}
                className={headerStyles.navLink + " focus:outline-none nav__link inline-flex items-center md:ml-12 md:text-blue-300"}
                partiallyActive={true}
                onClick={() => this.toggleHamburger()}
                to="/case-studies">
                <span className="md:text-white">Case studies</span>
              </Link>
              <Link
                activeClassName={headerStyles.isActive}
                className={headerStyles.navLink + " focus:outline-none nav__link inline-flex items-center md:ml-12 md:text-blue-300"}
                onClick={() => this.toggleHamburger()}
                to="/about">
                <span className="md:text-white">About us</span>
              </Link>
            </div>
            <a
              className={headerStyles.navContact + " nav__contact md:border-2 md:border-blue-300 focus:outline-none focus:bg-transparent hover:bg-transparent inline-flex items-center md:bg-blue-300 md:font-medium md:py-4 md:px-8 md:rounded-full"}
              onClick={() => this.toggleHamburger()}
              href="#contact">Contact
            </a>
          </div>
          <div className={headerStyles.socialNav + " hidden py-6"}>
            <a 
              aria-label="CVision’s LinkedIn" 
              className="duration-200 focus:outline-none focus:text-indigo-800 hover:text-indigo-800 mr-4 text-gray-600 transition-colors"
              href="https://www.linkedin.com/company/cvision-consulting-llc/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <svg 
                className="fill-current"
                height="1.2em" 
                width="1.2em">
                <use xlinkHref="#icon-linkedin2"></use>
              </svg>
            </a>
            <a
              aria-label="CVision’s GitHub"
              className="duration-200 focus:outline-none focus:text-indigo-800 hover:text-indigo-800 mr-4 text-gray-600 transition-colors"
              href="https://github.com/cvisionai"
              rel="noopener noreferrer"
              target="_blank"
            >
              <svg 
                className="fill-current"
                height="1.2em" 
                width="1.2em">
                <use xlinkHref="#icon-github"></use>
              </svg>
            </a>
          </div>
        </nav>
      </header>
    );
  }
};

export default Header;