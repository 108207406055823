import React from "react";
import { navigate } from "gatsby-link";
import { graphql, StaticQuery } from "gatsby";
import Recaptcha from "react-google-recaptcha";

const RECAPTCHA_KEY = "6LfwhfUaAAAAAMXOUbK9t5-KPs-j1l-DkCjE_kKu";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const ContactForm = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false, recaptchaChanged: false };
    this.recaptchaRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.name && this.state.email && this.state.description) {
      console.log("Form is complete!");
      const nameOk = this.state.name.length > 0 && /^[a-zA-Z ]+$/.test(this.state.name);
      console.log(`Name ok: ${nameOk}`);
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const emailOk = this.state.email.length > 0 && emailRegex.test(this.state.email);
      console.log(`Email ok: ${emailOk}`);
      const descOk = this.state.description.length > 0;
      console.log(`Desc ok: ${descOk}`);
      const recaptchaOk = this.state.recaptchaChanged;
      console.log(`Recaptcha ok: ${recaptchaOk}`);
      if (nameOk && emailOk && descOk && recaptchaOk && !this.state.isValidated) {
        console.log("Setting form to valid!");
        this.setState({isValidated: true});
      }
    } else {
      console.log("Form is incomplete!");
    }
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const recaptchaValue = this.recaptchaRef.current.getValue();
    const body = {
      "form-name": form.getAttribute("name"),
      "g-recaptcha-response": recaptchaValue,
      ...this.state,
    };
    console.log("Posting the following contact info:");
    console.log(body);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode(body),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    const { frontmatter } = this.props.data.markdownRemark;

    return (
      <div className="md:px-6">
        <div className="bg-indigo-800 max-w-screen-xl mx-auto -mt-56" id="contact">
          <div className="justify-between md:flex md:py-40 py-24 px-6">
            <div className="md:ml-16 md:pr-6 w-4/5">
              <h2 className="font-serif leading-tight max-w-sm md:text-5xl text-3xl text-white">
                {frontmatter.heading} <span className="text-blue-300">{frontmatter.headingHighlight}</span>
              </h2>
              <p className="max-w-xs md:leading-relaxed md:text-lg my-8 text-indigo-300">{frontmatter.text}</p>
            </div>
            <form
              className="md:mt-2 md:w-3/4 w-11/12"
              name="contact"
              method="POST"
              action="/contact/thanks/"
              data-netlify="true"
              data-netlify-recaptcha="true"
              onSubmit={this.handleSubmit}
            >
              <div className="mb-10 relative">
                <label className="sr-only" htmlFor="name">
                  Full name
                </label>
                <input
                  className="bg-transparent border-b border-blue-400 focus:outline-none focus:border-white py-2 rounded-none w-4/5"
                  type="text"
                  name="name"
                  onChange={this.handleChange}
                  id="name"
                  placeholder="Full name *"
                  required={true}
                />
              </div>
              <div className="mb-10 relative">
                <label className="sr-only" htmlFor="company">
                  Company or organization
                </label>
                <input
                  className="bg-transparent border-b border-blue-400 focus:outline-none focus:border-white py-2 rounded-none w-4/5"
                  type="text"
                  name="company"
                  onChange={this.handleChange}
                  id="company"
                  placeholder="Company or organization"
                  required={false}
                />
              </div>
              <div className="mb-10 relative">
                <label className="sr-only" htmlFor="email">
                  Email
                </label>
                <input
                  className="bg-transparent border-b border-blue-400 focus:outline-none focus:border-white py-2 rounded-none w-4/5"
                  type="email"
                  name="email"
                  onChange={this.handleChange}
                  id="email"
                  placeholder="Email *"
                  required={true}
                />
              </div>
              <div className="mb-10 relative">
                <label className="sr-only" htmlFor="description">
                  Short Description
                </label>
                <textarea
                  className="bg-transparent border-b border-blue-400 focus:outline-none focus:border-white py-2 rounded-none w-4/5"
                  name="description"
                  onChange={this.handleChange}
                  id="description"
                  placeholder="Short description *"
                  required={true}
                  rows="1"
                  style={{minHeight: '2.5625rem'}}
                />
              </div>
              <Recaptcha
               className="mb-10 relative"
               ref={this.recaptchaRef}
               sitekey={RECAPTCHA_KEY}
               size="normal"
               id="recaptcha-google"
               onChange={() => this.setState({recaptchaChanged: true})}
              />
              <button className="border-2 border-blue-300 bg-transparent focus:bg-blue-300 focus:outline-none focus:text-indigo-800 font-medium hover:bg-blue-300 hover:text-indigo-800 py-3 px-8 rounded-full text-white" type="submit" value="Send" disabled={!this.state.isValidated}>
              Send</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
};

export default () => (
  <StaticQuery
    query={graphql`
      query ContactQuery {
        markdownRemark(frontmatter: { key: { eq: "contact" } }) {
          frontmatter {
            key
            heading
            headingHighlight
            text
          }
        }
      }
    `}
    render={(data) => <ContactForm data={data} />}
  />
);
